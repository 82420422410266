<template>
  <div class="login">
    <div class="cover-container">
      <div class="cover py-5 bg-brand">
        <img src="@/assets/images/logos/logo.png" alt="" class="" />
      </div>
    </div>

    <div class="px-4">
      <div class="text-sm font-weight-bold h5 mb-4 mt-0 text-center">
        <span class="font-weight-bold h5 my-4 px-3 mt-0">اﻹشتراك في الخدمة</span>
      </div>
      
      <div v-if="isOTPSent">
        <div class="">
          <div class="text-center px-5 my-4">
            <div>الرجاء إدخال رمز التأكيد</div>
            <div class="font-weight-bold text-sm">تم إرسال الرمز لرقم هاتفك</div>
          </div>
          <div dir="ltr">
            <div class="d-flex justify-content-center px-5">
              <input type="text" class="form-control font-weight-bold text-center mx-1 py-2" v-model="num1" id='ist' ref="ist" maxlength="1" @input="clickEvent($event,'sec')"  placeholder="_">
              <input type="text" class="form-control font-weight-bold text-center mx-1 py-2" v-model="num2" id="sec" maxlength="1" @input="clickEvent($event,'third')" placeholder="_">
              <input type="text" class="form-control font-weight-bold text-center mx-1 py-2" v-model="num3" id="third" maxlength="1" @input="clickEvent($event,'fourth')" placeholder="_">
              <input type="text" class="form-control font-weight-bold text-center mx-1 py-2" v-model="num4" id="fourth" maxlength="1" @input="clickEvent($event,'fifth')" placeholder="_">
              <input type="hidden" class="" id="fifth" maxlength="1">
            </div>
          </div>
        </div>
        <div class="text-center p-5">
          <button class="btn btn-success mx-1 mb-2" @click="verifyOTP" :disabled="otp.length < 4 || otpVerificationLoading"><i class="la la-spinner la-spin me-2" v-if="otpVerificationLoading"></i> تأكيد</button>
        </div>
      </div>

      <div v-else>
        <div class="">
          <div class="text-center px-5 my-4">
            <div>قم باﻹشتراك في الخدمة للإستمتاع بالخدمة</div>
          </div>
          <div>
            <div class="d-flex justify-content-center px-4">
              <div class="col">
                <input type="text" class="form-control font-weight-bold text-center rounded-0 rounded-start mx-1 py-2 px-0 phone" v-model="phone" maxlength="9" placeholder="123456789">
              </div>
              <div class="w-25">
                <input type="text" class="form-control font-weight-bold text-center rounded-0 rounded-end border-start-0 mx-1 py-2 ps-0 key" v-model="key" maxlength="3">
              </div>
            </div>
          </div>
        </div>
        <div class="text-center p-5">
          <button class="btn btn-success mx-1 mb-2" @click="subscribeUser" :disabled="phone.length < 9 || subscriptionLoading"><i class="la la-spinner la-spin me-2" v-if="subscriptionLoading"></i> إشتراك</button>
        </div>
        <div class="text-center px-5">
            <div class="font-weight-bold card-secondary-lable text-sm">قيمة الاشتراك اليومي 120 جنيه فقط شاملة الضريبة تجدد تلقائيا</div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { computed, ref } from '@vue/reactivity';
import { watchEffect } from '@vue/runtime-core';
import { useToast } from 'vue-toastification'
import gql from 'graphql-tag';

export default {
  components: {},

  setup () {

    const toast = useToast()

    let subscriptionLoading = ref(false)
    let otpVerificationLoading = ref(false)
    let isOTPSent = ref(false)

    let phone = ref('')
    let key = ref('249')
    let requestId = ref('')

    let num1 = ref('')
    let num2 = ref('')
    let num3 = ref('')
    let num4 = ref('')

    let full_phone = computed(() => {
      return ""+ key.value + "" + phone.value;
    })

    let otp = computed(() => {
      return ""+ num1.value + "" + num2.value + "" + num3.value + "" + num4.value;
    })

    function clickEvent(first,last) {
			if(first.target.value.length && !isNaN(first.target.value)){
				document.getElementById(last).focus();
			} else {
        first.target.value = '';
      }
		} 

    watchEffect(() => {
      if (otp.value.length === 4) {
        console.log("Done ", otp.value)
      }
    })

    return {
      toast,
      clickEvent,
      subscriptionLoading,
      otpVerificationLoading,
      phone,
      key,
      num1,
      num2,
      num3,
      num4,
      otp,
      full_phone,
      isOTPSent,
      requestId,
    }
  },

  methods: {
    subscribeUser() {
      this.subscriptionLoading = true
      this.$apollo
        .mutate({
          mutation: gql`
            mutation ($input: SubscribeUserInput!) {
              subscribeUser(input: $input) {
                message
                code
                success
                payload
              }
            }
          `,
          // Parameters
          variables: {
            input: {
              phone: this.full_phone,
            }
          },
        })
        .then((data) => {
          // Result
          console.log(data.data.subscribeUser)
          if(data.data.subscribeUser.success) {
            if(data.data.subscribeUser.code === 201) {
              this.$store.commit('SET_TOKEN', data.data.subscribeUser.payload)
              this.toast.success(data.data.subscribeUser.message)
              this.$router.push({ name: 'Home' })
            } else {
              this.isOTPSent = true
              this.requestId = data.data.subscribeUser.payload
              this.toast.success(data.data.subscribeUser.message)
              setTimeout(() => {
                  this.$refs.ist.focus();
              }, 500);
            }
          }else {
            this.toast.error(data.data.subscribeUser.message)
          }
          this.subscriptionLoading = false;
        })
        .catch((error) => {
          console.log(error)
          this.subscriptionLoading = false

          this.reportError(error)
        });
    },

    verifyOTP() {
      this.otpVerificationLoading = true
      this.$apollo
        .mutate({
          mutation: gql`
            mutation ($input: VerifyOTPInput!) {
              verifyOTP(input: $input) {
                message
                code
                success
                payload
              }
            }
          `,
          // Parameters
          variables: {
            input: {
              phone: this.full_phone,
              requestId: this.requestId,
              otp: this.otp,
            }
          },
        })
        .then((data) => {
          // Result
          console.log(data.data.verifyOTP)
          if(data.data.verifyOTP.success) {
            this.$store.commit('SET_TOKEN', data.data.verifyOTP.payload)
            this.toast.success('تم اﻹشتراك بنجاح')
            this.$router.push({ name: 'Home' })
          } else {
            this.toast.success(data.data.verifyOTP.message)
          }
          this.otpVerificationLoading = false;
        })
        .catch((error) => {
          console.log(error)
          this.otpVerificationLoading = false

          this.reportError(error)
        });
    },

    reportError(error) {
      let { graphQLErrors } = error;
      if (graphQLErrors[0].extensions.category === "validation") {
          let validationErrors = graphQLErrors[0].extensions.validation;
          for (const [key, value] of Object.entries(validationErrors)) {
            this.toast.error(value[0])
          }
          
          this.toast.error('خطأ في البيانات')
      }
    }
  }

};
</script>


<style>

  .phone, .key {
    letter-spacing: .5rem;
  }


  /* input.form-control {
    text-align: center;
    height: 60px;
  } */
  
</style>